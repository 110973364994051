<template>
    <section class="section-narrow">
        <transition
            enter-active-class="animated fadeIn faster"
            leave-active-class="animated fadeOut faster"
            mode="out-in"
        >
            <router-view></router-view>
        </transition>
    </section>
</template>

<script>
export default {
    name: 'Landing'
}
</script>
